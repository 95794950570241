import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import Icon from "../components/Icon"

const About = ({ location }) => (
  <Layout location={location} >
    <SEO title="About" />
    <div className="row">
      <div className="col-md-8 content-left">
        <h1>About Us</h1>
        <p>M.L. Wray Automotive Repairs and Towing has been operating in the Shoalhaven area for approximately 47 Years, providing the best services and quality. The company began as a small workshop with only one employee, and over the years has grown to being located on large premises with eight employees.</p>
        <p>We can cater for all your motoring needs, from towing of your vehicle to servicing your vehicle.</p>
        <p>M. L. Wray Automotive Repairs and Towing has always assured you of top quality service and care at the right price.</p>
      </div>
      <div className="col-md-4 content-right">
        <h1>Get in touch!</h1>
        <p><a href="tel:0244220454"><Icon name="phone" />(02) 4422 0454</a></p>
        <p>
          <Link to="/contact" className="btn btn-primary">
            Online Enquiry<Icon name="arrow-right" />
          </Link>
        </p>
        <p>Licence No: MVRL24142</p>
      </div>
    </div>
  </Layout>
)

export default About